
import {
  propertyChangeRequestMapActions,
  propertyChangeRequestMapGetters,
  propertyChangeRequestMapMutations,
  propertyChangeRequestMapState
} from "@/store/modules/propertychangerequest";
import { companiesMapGetters } from "@/store/modules/companies";
import { StorageFormStatus } from "@/helpers/storageFormBaseInterface";
import { omit, get } from "lodash";
import { objectDifference } from "@/helpers";
import * as types from "@/store/mutation-types";
import { object } from "dot-object";
import { authMapGetters } from "@/store/modules/auth";
import { capitalizeFirstLetter } from "../../../../helpers/generalHelpers";
import { printSingleChangeRequest } from "@/helpers/downloadLossForm";
import ActivitiesMixin from "@/components/ActivitiesView/ActivitiesMixin.vue";
import VueWithMixins from "@/helpers/mixins";
import ChangeRequestMixin from "../../ChangeRequests/ChangeRequestMixin";

interface IData {
  errorMessage: string;
  successMessage: string;
  resourceNotFound: boolean;
  didInit: boolean;
  propertyChangeRequestData: any;
  pageDataInitialized: boolean;
  statusOptions: { label: string; value: string }[];
}

export default VueWithMixins(ChangeRequestMixin).extend({
  name: "PropertyChangeRequestDetailsView",
  mixins: [ActivitiesMixin],
  data(): IData {
    return {
      errorMessage: "",
      successMessage: "",
      resourceNotFound: false,
      didInit: false,
      propertyChangeRequestData: {},
      pageDataInitialized: false,
      statusOptions: [
        {
          label: "Not Submitted",
          value: "Not Submitted"
        },
        {
          label: "Submitted",
          value: "Submitted"
        },
        {
          label: "Received",
          value: "Received"
        },
        {
          label: "Issued",
          value: "Issued"
        }
      ]
    };
  },
  components: {
    ViewChangeRequestInsurerDetails: () =>
      import("@/components/ChangeRequests/ViewChangeRequestInsurerDetails.vue"),
    ViewChangeRequestPolicyDetails: () =>
      import("@/components/ChangeRequests/ViewChangeRequestPolicyDetails.vue"),
    ChangeRequestStatus: () =>
      import("@/components/ChangeRequests/ChangeRequestStatus.vue"),
    DisplayAttachment: () =>
      import("@/components/DataView/DisplayAttachment.vue")
  },
  computed: {
    ...authMapGetters(["getCurrentUser"]),
    propertyChangeRequestId(): string {
      return this.$route.params.propertychangerequest_id;
    },
    ...propertyChangeRequestMapState(["makingApiRequest", "editing"]),
    ...propertyChangeRequestMapGetters(["getPropertyChangeRequestById"]),
    original(): any {
      return this.getPropertyChangeRequestById(this.propertyChangeRequestId);
    },
    showApprovedButton(): boolean {
      return !!(
        this.editing &&
        (this.editing as any).status !== "approved" &&
        (this.editing as any).status !== "rejected"
      );
    },
    showDeclineButton(): boolean {
      return !!(
        this.editing &&
        (this.editing as any).status !== "rejected" &&
        (this.editing as any).status !== "approved"
      );
    },
    ...companiesMapGetters(["companies"]),
    agentApplicationID(): any {
      return this.$route.params.agentApplicationID;
    },
    updatedFields(): any {
      const toIgnore = ["data.agentInfo"];
      return omit(
        this.editing && this.original
          ? objectDifference(this.editing, this.original)
          : {},
        toIgnore
      );
    },
    primaryToolbar(): any {
      if (
        this.editing &&
        !this.editing.deleted &&
        (get(this.getCurrentUser, "role", "") === "admin" ||
          get(this.getCurrentUser, "role", "") === "underwriting")
      ) {
        return {
          text: "Save",
          key: "save",
          loading: this.makingApiRequest,
          disabled: Object.keys(this.updatedFields).length === 0
        };
      }
      return undefined;
    },
    topButtonSecondaryItems(): {
      text: string;
      key: string;
    } | null {
      if (get(this.editing, "deleted") || get(this.editing, "isLegacy")) {
        return null;
      }
      let options = [
        {
          title: "Print",
          command: "print"
        }
      ];
      let secondaryMenu = {
        text: "Actions",
        key: "actions",
        subItems: options
      };
      if (
        get(this.original, "status") === "Not Submitted" ||
        (get(this.getCurrentUser, "role") === "admin" &&
          get(this.original, "status") !== "Received" &&
          get(this.original, "status") !== "Submitted" &&
          get(this.original, "status") !== "Issued" &&
          !get(this.original, "printed"))
      ) {
        options.push({
          title: "Edit",
          command: "edit"
        });
      }
      return secondaryMenu;
    },
    queryOverride(): any {
      return {
        "data.policyChangeRequestId": this.editing?._id,
        activityType: "PolicyChangeRequestActivity"
      };
    }
  },
  created() {
    this.initializePageData();
  },
  methods: {
    ...propertyChangeRequestMapMutations({
      editField: types.SET_EDIT_FIELD
    }),
    ...propertyChangeRequestMapActions([
      "getPropertyChangeRequest",
      "updatePropertyChangeRequest"
    ]),
    // function to get the property of the object
    getProperty(data: object, property: keyof typeof object): string {
      return data ? data[property] : "";
    },
    getValue(data: object, property: string): string {
      return data ? `${data}.${property}` : "";
    },
    onToolBarSelectItem(event: string) {
      switch (event) {
        case "save":
          try {
            this.Submit();
          } catch (error) {
            this.$appNotifyError(error.message);
            this.$bugSnagClient.notify(error);
          }
          break;
        case "edit":
          this.$router
            .push(`/propertyChangeRequest/edit/${this.editing?._id}`)
            .catch(() => {});
          break;
        case "print":
          this.printCallback();
          break;
        default:
          break;
      }
    },
    changeStatus(action: StorageFormStatus): void {
      this.editField({
        key: "status",
        value: action
      });
    },
    async initializePageData() {
      try {
        await this.getPropertyChangeRequest(this.propertyChangeRequestId);
        const { data, ...rootFields } = this.editing as any;
        this.propertyChangeRequestData = {
          ...rootFields,
          ...data
        };
        this.pageDataInitialized = true;
      } catch (error) {
        this.resourceNotFound = true;
        this.$bugSnagClient.notify(error);
      }
    },
    async Submit() {
      if (this.editing) {
        const { status } = this.editing;
        await this.updatePropertyChangeRequest({
          id: this.propertyChangeRequestId,
          update: {
            status
          }
        });

        this.$appNotifySuccess("Status changed");
      }
    },
    async printCallback() {
      const policyPrefix =
        this.editing &&
        this.editing.data &&
        this.editing.data.policyInfo &&
        this.editing.data.policyInfo.policyPrefix
          ? this.editing.data.policyInfo.policyPrefix
          : undefined;
      await printSingleChangeRequest.bind(this)({
        changeRequestIds: this.editing?._id,
        policyPrefix,
        printAction: "propertychangerequest/printPropertyChangeRequest"
      });
    },
    capitalizeFirstLetter(word: string) {
      return capitalizeFirstLetter(word);
    }
  }
});
